<template>
  <v-container fluid>
    <v-form
      ref="form"
      v-model="validate"
      @submit.prevent="checkAdjustNote()">
      <v-row class="form-container rounded-lg secondary--text">
        <v-col cols="12">
          <h3>
            ฟอร์มกรอกข้อมูลการจอง
          </h3>
          <v-divider class="mt-3" />
        </v-col>
        <v-col
          v-if="isEdit"
          cols="12"
          md="10">
          <div class="mb-1">
            เลขที่การจอง
          </div>
          <div>
            {{ formData.orderNo || '231218-XXXXXX' }}
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5">
          <div class="mb-1">
            สถานะการจอง
          </div>
          <v-select
            v-model="formData.state"
            :rules="textBoxRules"
            :items="states"
            item-text="text"
            item-value="value"
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="5">
          <div class="mb-1">
            แนบสลิป
          </div>
          <file-uploader
            v-model="files"
            :max-file="1"
            label="อัปโหลดสลิป"
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="10">
          <div>
            จำนวนผู้เดินทางทั้งหมด
          </div>
          <v-divider class="my-2" />
          <v-row>
            <v-col
              cols="12"
              md="10">
              <passengers-section
                v-model="formData.passengers"
                @adjust="adjustPassenger($event)" />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="10">
          <div>
            รายละเอียดการจอง
          </div>
          <v-divider class="my-2" />
          <v-row>
            <v-col
              cols="12"
              md="6">
              <div class="mb-1">
                แพ็กเกจทัวร์
              </div>
              <v-autocomplete
                v-model="formData.tour"
                :items="tours"
                item-text="name"
                item-value="id"
                :search-input.sync="tourQuery.search"
                placeholder="EGYPT ไคโร กีซา เมมฟิส อเล็กซานเดรีย"
                :rules="textBoxRules"
                prepend-inner-icon="mdi-magnify"
                color="primary"
                outlined
                dense
                hide-details
                return-object
                required
                :loading="loading"
                :disabled="isPaid" />
            </v-col>
            <v-col
              cols="12"
              md="6">
              <div class="mb-1">
                รอบการเดินทาง
              </div>
              <v-select
                v-model="formData.package"
                :items="tourPackages"
                item-value="startDate"
                return-object
                outlined
                dense
                placeholder="โปรดเลือกแพ็กเกจทัวร์"
                hide-details
                required
                :rules="textBoxRules"
                :disabled="loading || isPaid"
                @input="selectPackage($event)">
                <template #selection="{ item }">
                  <span>
                    {{ item.startDate | dateFormat() }} - {{ item.endDate | dateFormat() }}
                  </span>
                </template>
                <template #item="{ item }">
                  <span>
                    {{ item.startDate | dateFormat() }} - {{ item.endDate | dateFormat() }}
                  </span>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="10">
          <div class="mb-1">
            รหัสสมาชิก
          </div>
          <v-text-field
            v-model="formData.booker.memberId"
            placeholder="ALLXXXX-XXXXX"
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="isEdit" />
        </v-col>
        <v-col
          cols="12"
          md="10">
          <div class="mb-1">
            คำนำหน้า
          </div>
          <v-select
            v-model="formData.booker.prefix"
            :rules="textBoxRules"
            :items="prefixes"
            item-text="text"
            item-value="value"
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="5">
          <div class="mb-1">
            ชื่อ
          </div>
          <v-text-field
            v-model="formData.booker.firstName"
            :rules="textBoxRules"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col
          cols="12"
          md="5">
          <div class="mb-1">
            นามสกุล
          </div>
          <v-text-field
            v-model="formData.booker.lastName"
            :rules="textBoxRules"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <!-- <v-col
          cols="12"
          md="5">
          <div class="mb-1">
            วัน เดือน ปีเกิด
          </div>
          <DatePickerDialog
            v-model="formData.booker.birthDate"
            format="D MMMM BBBB"
            :rules="textBoxRules"
            :disabled="loading" />
        </v-col> -->
        <v-col
          cols="12"
          md="5">
          <div class="mb-1">
            หมายเลขโทรศัพท์
          </div>
          <v-text-field
            v-model="formData.booker.tel"
            type="text"
            :rules="[...textBoxRules, ...telBoxRules]"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col
          cols="12"
          md="5">
          <div class="mb-1">
            อีเมล
          </div>
          <v-text-field
            v-model="formData.booker.email"
            :rules="emailBoxRules"
            type="email"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col
          cols="12"
          md="5">
          <div class="mb-1">
            เลขบัตรประจำตัวประชาชน / เลขที่หนังสือเดินทาง
          </div>
          <v-text-field
            v-model="formData.booker.citizenNo"
            :rules="[...textBoxRules, citizenNoValidate]"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <!-- <v-col
          cols="12"
          md="10">
          <div>
            ประกันภัย
          </div>
          <v-divider class="my-2" />
          <div
            v-if="!tourRooms.length"
            class="accent--text">
            โปรดเลือกแพ็กเกจทัวร์
          </div>
          <v-expansion-panels
            v-else
            class="rounded-lg mt-3"
            style="border: 1px solid #026EAA;">
            <v-expansion-panel
              v-for="insurance in tourInsurances"
              :key="`booker-insurance-${insurance.name}`"
              class="rounded-lg no-shadow-panel">
              <v-expansion-panel-header>
                <v-checkbox
                  v-model="formData.booker.insurances"
                  :value="insurance.name"
                  :ripple="false"
                  hide-details
                  class="mt-0" />
                <span class="font-weight-medium">
                  {{ insurance.name }}
                </span>
                <span class="font-weight-medium">
                  ราคา {{ showNumberFormat(insurance.price) }} บาท
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="secondary--text">
                  <v-col cols="12">
                    <v-divider />
                  </v-col>
                  <v-col cols="12">
                    <p class="font-weight-medium mb-2">
                      รายละเอียด
                    </p>
                    <p class="accent--text mb-2">
                      {{ insurance.description }}
                    </p>
                    <p class="font-weight-medium mb-2">
                      ความคุ้มครอง
                    </p>
                    <p class="accent--text mb-0">
                      {{ insurance.protections }}
                    </p>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col> -->
        <v-col
          cols="12"
          md="10">
          <div>
            รูปภาพ Passport
          </div>
          <v-divider class="mt-2 mb-4" />
          <image-uploader
            v-model="formData.passports"
            name="passports"
            multiple
            :max-file="getFollowers().length"
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="10">
          <div>
            รายชื่อผู้ร่วมเดินทาง
          </div>
          <v-divider class="mt-3 mb-4" />
          <v-row>
            <v-col
              cols="12"
              md="3"
              class="d-flex flex-row flex-md-column align-center"
              style="height: fit-content; gap: 1rem; overflow-x: auto;">
              <v-btn
                v-for="(follower, f) in formData.followers"
                :key="`follower-btn-${f}`"
                color="primary"
                outlined
                block
                :ripple="false"
                depressed
                x-large
                @click="selectFollower(f)">
                <div
                  class="d-flex flex-column justify-center"
                  style="gap: 0.5rem;">
                  <span
                    :class="{
                      'text-decoration-underline': f === followerFocusIndex
                    }">
                    ผู้ร่วมเดินทาง {{ f + 1 }}
                  </span>
                  <span class="accent--text">
                    {{ `${follower.firstName || 'ชื่อ'} ${follower.lastName || 'นามสกุล'}` }}
                  </span>
                </div>
              </v-btn>
              <v-btn
                color="primary"
                outlined
                block
                :ripple="false"
                depressed
                x-large
                :disabled="loading || isPaid"
                @click="addFollower()">
                <v-icon>
                  mdi-plus
                </v-icon>
                <span>
                  เพิ่มผู้ร่วมเดินทาง
                </span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="9">
              <v-row v-if="formData.followers.length">
                <v-col
                  cols="12"
                  md="4">
                  <div class="mb-1">
                    คำนำหน้า
                  </div>
                  <v-select
                    v-model="formData.followers[followerFocusIndex].prefix"
                    :rules="textBoxRules"
                    :items="prefixes"
                    item-text="text"
                    item-value="value"
                    color="primary"
                    outlined
                    dense
                    hide-details
                    required
                    :disabled="loading" />
                </v-col>
                <v-col
                  cols="12"
                  md="4">
                  <div class="mb-1">
                    ชื่อ
                  </div>
                  <v-text-field
                    v-model="formData.followers[followerFocusIndex].firstName"
                    :rules="textBoxRules"
                    color="primary"
                    outlined
                    dense
                    hide-details
                    required
                    :disabled="loading" />
                </v-col>
                <v-col
                  cols="12"
                  md="4">
                  <div class="mb-1">
                    นามสกุล
                  </div>
                  <v-text-field
                    v-model="formData.followers[followerFocusIndex].lastName"
                    :rules="textBoxRules"
                    color="primary"
                    outlined
                    dense
                    hide-details
                    required
                    :disabled="loading" />
                </v-col>
                <v-col cols="12">
                  <div class="mb-1">
                    หมายเลขโทรศัพท์
                  </div>
                  <v-text-field
                    v-model="formData.followers[followerFocusIndex].tel"
                    type="text"
                    :rules="[...textBoxRules, ...telBoxRules]"
                    color="primary"
                    outlined
                    dense
                    hide-details
                    required
                    :disabled="loading" />
                </v-col>
                <v-col cols="12">
                  <div class="mb-1">
                    เลขบัตรประจำตัวประชาชน / เลขที่หนังสือเดินทาง
                  </div>
                  <v-text-field
                    v-model="formData.followers[followerFocusIndex].citizenNo"
                    :rules="[...textBoxRules, citizenNoValidate]"
                    color="primary"
                    outlined
                    dense
                    hide-details
                    required
                    :disabled="loading" />
                </v-col>
                <!-- <v-col cols="12">
                  <div>
                    ประกันภัย
                  </div>
                  <v-divider class="my-2" />
                  <div
                    v-if="!tourRooms.length"
                    class="accent--text">
                    โปรดเลือกแพ็กเกจทัวร์
                  </div>
                  <v-expansion-panels
                    v-else
                    class="rounded-lg mt-3"
                    style="border: 1px solid #026EAA;">
                    <v-expansion-panel
                      v-for="insurance in tourInsurances"
                      :key="`follower-insurance-${insurance.name}`"
                      class="rounded-lg no-shadow-panel">
                      <v-expansion-panel-header>
                        <v-checkbox
                          v-model="formData.followers[followerFocusIndex].insurances"
                          :value="insurance.name"
                          :ripple="false"
                          hide-details
                          class="mt-0" />
                        <span class="font-weight-medium">
                          {{ insurance.name }}
                        </span>
                        <span class="font-weight-medium">
                          ราคา {{ showNumberFormat(insurance.price) }} บาท
                        </span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row class="secondary--text">
                          <v-col cols="12">
                            <v-divider />
                          </v-col>
                          <v-col cols="12">
                            <p class="font-weight-medium mb-2">
                              รายละเอียด
                            </p>
                            <p class="accent--text mb-2">
                              {{ insurance.description }}
                            </p>
                            <p class="font-weight-medium mb-2">
                              ความคุ้มครอง
                            </p>
                            <p class="accent--text mb-0">
                              {{ insurance.protections }}
                            </p>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col> -->
                <v-col
                  cols="12"
                  class="d-flex justify-end">
                  <v-btn
                    color="error"
                    outlined
                    :ripple="false"
                    depressed
                    :disabled="loading || isPaid"
                    @click="removeFollower()">
                    <v-icon>
                      mdi-trash-can-outline
                    </v-icon>
                    <span>
                      ลบผู้ร่วมเดินทาง
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
              <div
                v-else
                class="accent--text d-flex justify-center align-center"
                style="height: 100%;">
                ไม่มีผู้ร่วมเดินทาง
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="10">
          <div>
            ประเภทห้อง
          </div>
          <v-divider class="mt-2 mb-4" />
          <div
            v-if="!tourRooms.length"
            class="accent--text">
            โปรดเลือกแพ็กเกจทัวร์
          </div>
          <v-row v-else>
            <v-col
              v-for="(room, r) in formData.rooms"
              :key="`order-room-${r}`"
              cols="12">
              <v-row>
                <v-col
                  cols="12"
                  md="5">
                  <v-select
                    v-model="formData.rooms[r].name"
                    :items="tourRooms"
                    item-text="text"
                    item-value="name"
                    color="primary"
                    label="ประเภทห้อง"
                    placeholder="โปรดเลือก"
                    outlined
                    dense
                    return-object
                    required
                    hide-details
                    :rules="textBoxRules"
                    :disabled="loading"
                    @input="selectRoom($event, r)" />
                </v-col>
                <v-col
                  cols="12"
                  md="5">
                  <v-row>
                    <v-col
                      v-for="(mate, m) in room.roommates"
                      :key="`order-room-${r}-mate-${m}`"
                      cols="12">
                      <v-text-field
                        v-model="formData.rooms[r].roommates[m]"
                        :label="`ผู้เข้าพักคนที่ ${m + 1}`"
                        placeholder="นายสมจิตร ใจดี..."
                        color="primary"
                        outlined
                        dense
                        hide-details
                        :disabled="loading || !formData.rooms[r].name" />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    color="error"
                    icon
                    :disabled="formData.rooms.length <= 1"
                    @click="removeRoom(r)">
                    <v-icon>
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <div class="d-flex justify-end mt-4">
                <v-btn
                  style="width: fit-content; background-color: white;"
                  color="primary"
                  outlined
                  depressed
                  :ripple="false"
                  :disabled="formData.rooms.length === tourRooms.length"
                  @click="addRoom()">
                  <v-icon>
                    mdi-plus-circle-outline
                  </v-icon>
                  <span>
                    เพิ่มประเภทห้อง
                  </span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="10">
          <div>
            ที่นั่งบนเครื่องบิน
          </div>
          <v-divider class="my-2" />
          <div
            v-if="!tourSeats.length"
            class="accent--text">
            โปรดเลือกแพ็กเกจทัวร์
          </div>
          <v-row v-else>
            <v-col
              v-for="(seat, r) in formData.seats"
              :key="`order-seat-${r}`"
              cols="12">
              <v-row>
                <v-col
                  cols="12"
                  md="5">
                  <v-select
                    v-model="formData.seats[r].name"
                    :items="filterSeats"
                    item-text="text"
                    item-value="name"
                    color="primary"
                    label="อัปเกรดที่นั่ง"
                    placeholder="โปรดเลือก"
                    outlined
                    dense
                    return-object
                    required
                    hide-details
                    :rules="textBoxRules"
                    :disabled="loading"
                    @input="selectSeat($event, r)" />
                </v-col>
                <v-col
                  cols="12"
                  md="5">
                  <v-select
                    v-model.number="formData.seats[r].qty"
                    :items="availableSeats"
                    item-text="text"
                    item-value="value"
                    color="primary"
                    label="จำนวน"
                    placeholder="โปรดเลือก"
                    outlined
                    dense
                    required
                    hide-details
                    :rules="[...textBoxRules, isSeatEnough]"
                    :disabled="loading" />
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    color="error"
                    icon
                    :disabled="formData.seats.length <= 1"
                    @click="removeSeat(r)">
                    <v-icon>
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <div class="d-flex justify-end mt-4">
                <v-btn
                  style="width: fit-content; background-color: white;"
                  color="primary"
                  outlined
                  depressed
                  :ripple="false"
                  :disabled="formData.seats.length === passengersQty"
                  @click="addSeat()">
                  <v-icon>
                    mdi-plus-circle-outline
                  </v-icon>
                  <span>
                    เพิ่มที่นั่ง
                  </span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="10">
          <div>
            ที่อยู่สำหรับจัดส่งเอกสาร
          </div>
          <v-divider class="mt-3" />
        </v-col>
        <v-col
          cols="12"
          md="5">
          <div class="mb-1">
            ชื่อ
          </div>
          <v-text-field
            v-model="formData.documentAddress.firstName"
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="5">
          <div class="mb-1">
            นามสกุล
          </div>
          <v-text-field
            v-model="formData.documentAddress.lastName"
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="5">
          <div class="mb-1">
            หมายเลขโทรศัพท์
          </div>
          <v-text-field
            v-model="formData.documentAddress.tel"
            type="text"
            :rules="telBoxRules"
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="10">
          <v-row>
            <v-col cols="12">
              <div class="mb-1">
                ที่อยู่
              </div>
              <v-text-field
                v-model="formData.documentAddress.address"
                color="primary"
                outlined
                dense
                hide-details
                required
                :disabled="loading" />
            </v-col>
            <v-col
              cols="12"
              md="3">
              <div class="mb-1">
                ถนน
              </div>
              <v-text-field
                v-model="formData.documentAddress.road"
                color="primary"
                outlined
                dense
                hide-details
                required
                :disabled="loading" />
            </v-col>
            <v-col
              cols="12"
              md="3">
              <div class="mb-1">
                ซอย
              </div>
              <v-text-field
                v-model="formData.documentAddress.alley"
                color="primary"
                outlined
                dense
                hide-details
                required
                :disabled="loading" />
            </v-col>
            <v-col
              cols="12"
              md="3">
              <div class="mb-1">
                แขวง / ตำบล
              </div>
              <v-text-field
                v-model="formData.documentAddress.subDistrict"
                color="primary"
                outlined
                dense
                hide-details
                required
                :disabled="loading" />
            </v-col>
            <v-col
              cols="12"
              md="3">
              <div class="mb-1">
                เขต / อำเภอ
              </div>
              <v-text-field
                v-model="formData.documentAddress.district"
                color="primary"
                outlined
                dense
                hide-details
                required
                :disabled="loading" />
            </v-col>
            <v-col
              cols="12"
              md="6">
              <div class="mb-1">
                จังหวัด
              </div>
              <v-text-field
                v-model="formData.documentAddress.province"
                color="primary"
                outlined
                dense
                hide-details
                required
                :disabled="loading" />
            </v-col>
            <v-col
              cols="12"
              md="6">
              <div class="mb-1">
                รหัสไปรษณีย์
              </div>
              <v-text-field
                v-model="formData.documentAddress.postcode"
                type="number"
                :rules="postcodeRules"
                color="primary"
                outlined
                dense
                hide-details
                required
                :disabled="loading" />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="10">
          <div>
            หมายเหตุอื่นๆ เพิ่มเติม
          </div>
          <v-divider class="mt-2 mb-4" />
          <v-textarea
            v-model="formData.note"
            placeholder="มีผู้สูงอายุมาด้วย..."
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="loading" />
        </v-col>
      </v-row>
      <v-row class="form-container rounded-lg secondary--text mt-6">
        <v-col cols="12">
          <h3>
            สรุปออเดอร์
          </h3>
          <v-divider class="mt-3" />
        </v-col>
        <v-col
          cols="4"
          md="3">
          <span class="font-weight-bold">
            เลขที่แพ็กเกจทัวร์
          </span>
        </v-col>
        <v-col
          cols="8"
          md="7">
          <span v-if="formData.tour">
            {{ formData.tour.id }}
          </span>
          <span v-else>
            โปรดเลือกแพ็กเกจทัวร์
          </span>
        </v-col>
        <v-col
          cols="4"
          md="3">
          <span class="font-weight-bold">
            ชื่อแพ็กเกจ
          </span>
        </v-col>
        <v-col
          cols="8"
          md="7">
          <span v-if="formData.tour">
            {{ formData.tour.name }}
          </span>
          <span v-else>
            โปรดเลือกแพ็กเกจทัวร์
          </span>
        </v-col>
        <v-col
          cols="4"
          md="3">
          <span class="font-weight-bold">
            เวนเดอร์
          </span>
        </v-col>
        <v-col
          cols="8"
          md="7">
          <span v-if="formData.tour">
            {{ formData.tour.vendor.name }}
          </span>
          <span v-else>
            โปรดเลือกแพ็กเกจทัวร์
          </span>
        </v-col>
        <v-col
          cols="4"
          md="3">
          <span class="font-weight-bold">
            จำนวนคนทั้งหมด
          </span>
        </v-col>
        <v-col
          cols="8"
          md="7">
          <span>
            {{ `${passengersQty} คน` }}
          </span>
        </v-col>
        <v-col
          cols="4"
          md="3">
          <ul
            class="pl-10"
            style="list-style-type: disc;">
            <li>
              <span>
                ผู้ใหญ่
              </span>
            </li>
          </ul>
        </v-col>
        <v-col
          cols="8"
          md="7">
          <span>
            {{ `${formData.passengers.adultWithTicket + formData.passengers.adultWithOutTicket} คน` }}
          </span>
        </v-col>
        <v-col
          cols="4"
          md="3">
          <ul
            class="pl-10"
            style="list-style-type: disc;">
            <li>
              <span>
                เด็ก
              </span>
            </li>
          </ul>
        </v-col>
        <v-col
          cols="8"
          md="7">
          <span>
            {{ `${formData.passengers.kidWithTicket + formData.passengers.kidWithOutTicket} คน` }}
          </span>
        </v-col>
        <v-col
          cols="4"
          md="3">
          <span class="font-weight-bold">
            ประเภททัวร์
          </span>
        </v-col>
        <v-col
          cols="8"
          md="7">
          <span v-if="formData.tour">
            {{ formData.tour.type }}
          </span>
          <span v-else>
            โปรดเลือกแพ็กเกจทัวร์
          </span>
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col
          cols="12"
          md="10">
          <span class="font-weight-bold">
            ราคาผู้ใหญ่
          </span>
        </v-col>
        <v-col cols="4">
          <ul
            class="pl-10"
            style="list-style-type: disc;">
            <li>
              <span>
                รวมตั๋วเครื่องบิน
              </span>
            </li>
          </ul>
        </v-col>
        <v-col
          cols="4"
          md="3">
          <span
            v-if="formData.package"
            class="accent--text">
            {{ `${showNumberFormat(getPackage('adult').price)} x ${getTickets('adult').quantity}` }}
          </span>
          <span v-else>
            โปรดเลือกแพ็กเกจทัวร์
          </span>
        </v-col>
        <v-col
          cols="4"
          md="3"
          class="text-right">
          <span v-if="formData.package">
            {{ `฿ ${showNumberFormat(getTickets('adult').net)}` }}
          </span>
        </v-col>
        <v-col cols="4">
          <ul
            class="pl-10"
            style="list-style-type: disc;">
            <li>
              <span>
                ไม่รวมตั๋วเครื่องบิน
              </span>
            </li>
          </ul>
        </v-col>
        <v-col
          cols="4"
          md="3">
          <span
            v-if="formData.package"
            class="accent--text">
            {{ `${showNumberFormat(getPackage('adult').withOutTicket)} x ${getTickets('adult', false).quantity}` }}
          </span>
          <span v-else>
            โปรดเลือกแพ็กเกจทัวร์
          </span>
        </v-col>
        <v-col
          cols="4"
          md="3"
          class="text-right">
          <span v-if="formData.package">
            {{ `฿ ${showNumberFormat(getTickets('adult', false).net)}` }}
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10">
          <span class="font-weight-bold">
            ราคาเด็ก
          </span>
        </v-col>
        <v-col cols="4">
          <ul
            class="pl-10"
            style="list-style-type: disc;">
            <li>
              <span>
                รวมตั๋วเครื่องบิน
              </span>
            </li>
          </ul>
        </v-col>
        <v-col
          cols="4"
          md="3">
          <span
            v-if="formData.package"
            class="accent--text">
            {{ `${showNumberFormat(getPackage('kid').price)} x ${getTickets('kid').quantity}` }}
          </span>
          <span v-else>
            โปรดเลือกแพ็กเกจทัวร์
          </span>
        </v-col>
        <v-col
          cols="4"
          md="3"
          class="text-right">
          <span v-if="formData.package">
            {{ `฿ ${showNumberFormat(getTickets('kid').net)}` }}
          </span>
        </v-col>
        <v-col cols="4">
          <ul
            class="pl-10"
            style="list-style-type: disc;">
            <li>
              <span>
                ไม่รวมตั๋วเครื่องบิน
              </span>
            </li>
          </ul>
        </v-col>
        <v-col
          cols="4"
          md="3">
          <span
            v-if="formData.package"
            class="accent--text">
            {{ `${showNumberFormat(getPackage('kid').withOutTicket)} x ${getTickets('kid', false).quantity}` }}
          </span>
          <span v-else>
            โปรดเลือกแพ็กเกจทัวร์
          </span>
        </v-col>
        <v-col
          cols="4"
          md="3"
          class="text-right">
          <span v-if="formData.package">
            {{ `฿ ${showNumberFormat(getTickets('kid', false).net)}` }}
          </span>
        </v-col>
        <v-col
          cols="12"
          md="10">
          <span class="font-weight-bold">
            ประเภทห้อง
          </span>
        </v-col>
        <template v-for="(room, i) in getRooms()">
          <v-col
            :key="`room-name-${i}`"
            cols="4">
            <ul
              class="pl-10"
              style="list-style-type: disc;">
              <li>
                <span>
                  {{ room.name }}
                </span>
              </li>
            </ul>
          </v-col>
          <v-col
            :key="`room-quantity-${i}`"
            cols="4"
            md="3">
            <span class="accent--text">
              {{ `${showNumberFormat(room.price)} x ${room.quantity}` }}
            </span>
          </v-col>
          <v-col
            :key="`room-net-${i}`"
            cols="4"
            md="3"
            class="text-right">
            <span>
              {{ `฿ ${showNumberFormat(room.net)}` }}
            </span>
          </v-col>
        </template>
        <v-col
          cols="12"
          md="10">
          <span class="font-weight-bold">
            อัพเกรดที่นั่ง
          </span>
        </v-col>
        <template v-for="(seat, i) in getSeats()">
          <v-col
            :key="`seat-name-${i}`"
            cols="4">
            <ul
              class="pl-10"
              style="list-style-type: disc;">
              <li>
                <span>
                  {{ seat.name }}
                </span>
              </li>
            </ul>
          </v-col>
          <v-col
            :key="`seat-quantity-${i}`"
            cols="4"
            md="3">
            <span class="accent--text">
              {{ `${showNumberFormat(seat.price)} x ${seat.quantity}` }}
            </span>
          </v-col>
          <v-col
            :key="`seat-net-${i}`"
            cols="4"
            md="3"
            class="text-right">
            <span>
              {{ `฿ ${showNumberFormat(seat.net)}` }}
            </span>
          </v-col>
        </template>
        <!-- <v-col
          cols="12"
          md="10">
          <span class="font-weight-bold">
            ประกันภัย
          </span>
        </v-col>
        <template v-for="ins in getInsurances()">
          <v-col
            :key="`col-1-${ins.name}`"
            cols="4">
            <ul
              class="pl-10"
              style="list-style-type: disc;">
              <li>
                <span>
                  {{ ins.name }}
                </span>
              </li>
            </ul>
          </v-col>
          <v-col
            :key="`col-2-${ins.name}`"
            cols="4"
            md="3">
            <span class="accent--text">
              {{ `${showNumberFormat(ins.price)} x ${ins.quantity}` }}
            </span>
          </v-col>
          <v-col
            :key="`col-3-${ins.name}`"
            cols="4"
            md="3"
            class="text-right">
            <span>
              {{ `฿ ${showNumberFormat(ins.net)}` }}
            </span>
          </v-col>
        </template> -->
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="4">
          <span class="font-weight-bold">
            โค้ดส่วนลด
          </span>
        </v-col>
        <v-col
          cols="4"
          md="3">
          <button
            class="discount-btn"
            type="button"
            :disabled="!formData.booker.memberId || !formData.package"
            @click="showDiscountDialog()">
            {{ formData.discountCode || 'กรอกโค้ดส่วนลด' }}
          </button>
          <v-btn
            v-if="formData.discountCode"
            type="button"
            icon
            color="error"
            small
            class="ml-2"
            @click="clearDiscount()">
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="4"
          md="3">
          <v-text-field
            v-model.number="formData.discountPrice"
            type="number"
            color="primary"
            prepend-inner-icon="mdi-currency-thb"
            :rules="[
              (v) => v >= 0 || 'กรุณาระบุไม่ต่ำกว่า 0'
            ]"
            outlined
            hide-details
            dense
            disabled />
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="4">
          <span class="font-weight-bold">
            ปรับยอดรวม
          </span>
        </v-col>
        <v-col cols="4">
        </v-col>
        <v-col
          cols="4"
          md="2">
          <v-text-field
            v-model.number="formData.adjustPrice"
            type="number"
            color="primary"
            :prepend-inner-icon="formData.adjustType === 'add' ? 'mdi-plus' : 'mdi-minus'"
            :rules="[
              (v) => v >= 0 || 'กรุณาระบุไม่ต่ำกว่า 0'
            ]"
            outlined
            hide-details
            dense
            :disabled="loading || isPaid"
            @click:prepend-inner="toggleAdjustType()" />
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col
          cols="4"
          md="3">
          <span class="font-weight-bold">
            ราคารวมสุทธิ
          </span>
        </v-col>
        <v-col
          cols="8"
          md="7"
          class="text-right">
          <span class="font-weight-bold">
            {{ `฿ ${showNumberFormat(formData.net)}` }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            outlined
            block
            :ripple="false"
            depressed
            :disabled="loading"
            style="background-color: white;"
            @click="onBack()">
            ยกเลิก
          </v-btn>
        </v-col>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            block
            :ripple="false"
            depressed
            :loading="loading"
            @click="checkAdjustNote()">
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <discount-dialog
      v-model="isShowDiscountDialog"
      @select="applyPrivilege($event)" />
    <adjust-note-dialog
      v-model="isShowAdjustNoteDialog"
      :text="formData.adjustNote"
      @confirm="onConfirm($event)" />
  </v-container>
</template>

<script>
import OrdersProvider from '@/resources/orders.provider'
import ToursProvider from '@/resources/tours.provider'
import MembersProvider from '@/resources/members.provider'
import UploaderProvider from '@/resources/uploader.provider'
import PrivilegesProvider from '@/resources/privileges.provider'
// import DatePickerDialog from '@/components/DatePickerDialog.vue'
import ImageUploader from '@/components/ImageUploader.vue'
import FileUploader from '@/components/FileUploader.vue'
import DiscountDialog from '../components/DiscountDialog.vue'
import AdjustNoteDialog from '../components/AdjustNoteDialog.vue'
import PassengersSection from '../components/PassengersSection.vue'

const OrdersService = new OrdersProvider()
const ToursService = new ToursProvider()
const MembersService = new MembersProvider()
const UploaderService = new UploaderProvider()
const PrivilegesService = new PrivilegesProvider()

export default {
  components: {
    // DatePickerDialog,
    ImageUploader,
    FileUploader,
    DiscountDialog,
    AdjustNoteDialog,
    PassengersSection
  },
  data: () => ({
    loading: false,
    validate: false,
    textBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน'
    ],
    telBoxRules: [
      (v) => (v ? (/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/g.test(v) || 'กรุณากรอกข้อมูลให้ถูกต้อง') : true)
    ],
    emailBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน',
      (v) => /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/g.test(v) || 'กรุณากรอกข้อมูลให้ถูกต้อง'
    ],
    postcodeRules: [
      (v) => `${v}`.length <= 5 || 'กรุณากรอกข้อมูลให้ถูกต้อง'
    ],
    tourQuery: {
      page: 1,
      limit: 20,
      search: ''
    },
    searchClearing: false,
    searchTimeout: false,
    formData: {
      orderNo: '',
      slipImageName: '',
      slipImageUrl: '',
      tour: null,
      member: null,
      package: null,
      booker: {
        age: 'adult',
        memberId: '',
        prefix: 'mr',
        firstName: '',
        lastName: '',
        birthDate: '',
        email: '',
        tel: '',
        citizenNo: '',
        room: {
          name: '',
          price: 0
        },
        insurances: [],
        seat: {
          name: '',
          price: 0
        },
        meal: {
          name: '',
          price: 0
        },
        includeTicket: true
      },
      followers: [],
      rooms: [
        {
          name: '',
          price: 0,
          qty: 0,
          capacity: 1,
          roommates: ['']
        }
      ],
      seats: [
        {
          name: '',
          description: '',
          price: 0,
          qty: 0
        }
      ],
      passports: [],
      passengers: {
        adultWithTicket: 1,
        adultWithOutTicket: 0,
        kidWithTicket: 0,
        kidWithOutTicket: 0
      },
      documentAddress: {
        firstName: '',
        lastName: '',
        tel: '',
        address: '',
        road: '',
        alley: '',
        subDistrict: '',
        district: '',
        province: '',
        postcode: ''
      },
      note: '',
      discountCode: '',
      discountPrice: 0,
      adjustPrice: 0,
      adjustType: 'add',
      adjustNote: '',
      net: 0,
      state: 'confirmed',
      payments: []
    },
    ages: [
      {
        text: 'ผู้ใหญ่',
        value: 'adult'
      },
      {
        text: 'เด็ก 0 - 12 ปี',
        value: 'kid'
      }
    ],
    prefixes: [
      {
        text: 'นาย',
        value: 'mr'
      },
      {
        text: 'นาง',
        value: 'ms'
      },
      {
        text: 'นางสาว',
        value: 'mrs'
      }
    ],
    files: [],
    tours: [],
    followerFocusIndex: -1,
    memoAdjustPrice: 0,
    memoAdjustType: 'add',
    isShowDiscountDialog: false,
    isShowAdjustNoteDialog: false,
    isPaid: false
  }),
  computed: {
    isEdit () {
      return !!this.$route.params?.id
    },
    states () {
      if (this.isEdit) {
        return [
          // {
          //   text: 'รอตรวจสอบเอกสาร',
          //   value: 'inspecting'
          // },
          // {
          //   text: 'รอยืนยันการจอง',
          //   value: 'pending'
          // },
          {
            text: 'รอชำระเงิน',
            value: 'confirmed'
          },
          {
            text: 'ชำระเงินเสร็จสิ้น',
            value: 'paid'
          },
          {
            text: 'ยกเลิก',
            value: 'canceled'
          }
        ]
      }

      return [
        // {
        //   text: 'รอตรวจสอบเอกสาร',
        //   value: 'inspecting'
        // },
        // {
        //   text: 'รอยืนยันการจอง',
        //   value: 'pending'
        // },
        {
          text: 'รอชำระเงิน',
          value: 'confirmed'
        }
      ]
    },
    tourRooms () {
      const tour = this.tours.find((t) => t.id === this.formData.tour?.id)

      if (!tour?.rooms?.length) {
        return []
      }

      return tour.rooms.map((r) => ({
          text: r.price ? `${r.name} ราคา ${this.showNumberFormat(r.price)} บาท` : r.name,
          name: r.name,
          price: r.price,
          capacity: r.capacity
        }))
    },
    filterRooms () {
      const currentRooms = this.formData.rooms.map((r) => r.name).filter(Boolean)

      return this.tourRooms.map((r) => ({
        ...r,
        disabled: currentRooms.includes(r.name)
      }))
    },
    tourSeats () {
      if (!this.formData.tour?.seats?.length) {
        return []
      }

      return this.formData.tour.seats.map((r) => ({
          text: r.price ? `${r.name} ราคา ${this.showNumberFormat(r.price)} บาท` : r.name,
          name: r.name,
          price: r.price
        }))
    },
    filterSeats () {
      const currentSeats = this.formData.seats.map((r) => r.name).filter(Boolean)

      return this.tourSeats.map((r) => ({
        ...r,
        disabled: currentSeats.includes(r.name)
      }))
    },
    availableSeats () {
      // const selected = this.formData.seats.reduce((sum, s) => sum + s.qty, 0)
      // const diff = this.passengersQty - selected
      const seats = Array.from(Array(this.passengersQty), (_, index) => index + 1)

      return seats.map((s) => ({
        text: `${s}`,
        value: s
        // disabled: s > diff
      }))
    },
    tourInsurances () {
      return this.formData.tour?.insurances || []
    },
    tourPackages () {
      const tour = this.tours.find((t) => t.id === this.formData.tour?.id)

      return tour?.packages || []
    },
    packageText () {
      if (!this.formData.package?.startDate || !this.formData.package?.endDate) {
        return ''
      }

      const startDate = this.$options.filters.dateFormat(this.formData.package.startDate)
      const endDate = this.$options.filters.dateFormat(this.formData.package.endDate)

      return `${startDate} - ${endDate}`
    },
    passengersQty () {
      return Object.keys(this.formData.passengers).reduce((sum, key) => sum + this.formData.passengers[key], 0)
    }
  },
  watch: {
    'formData': {
      handler () {
        this.calcPrice()
      },
      deep: true
    },
    'formData.tour': {
      handler () {
        if (
          this.formData.package
          && this.formData.tour?.packages?.some((t) => t.startDate === this.formData.package.startDate)
        ) {
          return
        }

        this.formData.package = this.formData.tour?.packages[0] || null
      },
      deep: true
    },
    'formData.followers': {
      handler () {
        const followers = this.getFollowers()

        this.formData.passengers = {
          adultWithTicket: followers.filter((f) => f.age === 'adult' && f.includeTicket).length,
          adultWithOutTicket: followers.filter((f) => f.age === 'adult' && !f.includeTicket).length,
          kidWithTicket: followers.filter((f) => f.age === 'kid' && f.includeTicket).length,
          kidWithOutTicket: followers.filter((f) => f.age === 'kid' && !f.includeTicket).length
        }
      },
      deep: true
    },
    'tourQuery.search': {
      handler () {
        if (this.loading) {
          return
        }

        clearTimeout(this.searchTimeout)

        this.searchTimeout = setTimeout(() => {
          if (!this.searchClearing) {
            this.getTours()
          }

          this.searchClearing = false
        }, 300)
      },
      deep: true
    }
  },
  mounted () {
    if (this.isEdit) {
      this.getItemById()
    }
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'OrderList' })
    },
    getFollowers (includeBooker = true) {
      if (includeBooker) {
        return [
          this.formData.booker,
          ...this.formData.followers
        ]
      }

      return this.formData.followers
    },
    getPackage (type) {
      return this.formData.package && this.formData.package[type]
        ? this.formData.package[type]
        : {
            price: 0,
            withOutTicket: 0
          }
    },
    getAdults () {
      return this.getFollowers().filter((t) => t.age === 'adult')
    },
    getKids () {
      return this.getFollowers().filter((t) => t.age === 'kid')
    },
    getTickets (type, includeTicket = true) {
      const travelers = type === 'adult' ? this.getAdults() : this.getKids()
      const quantity = travelers.filter((t) => t.includeTicket === includeTicket).length

      return {
        quantity,
        net: includeTicket ? this.getPackage(type).price * quantity : this.getPackage(type).withOutTicket * quantity
      }
    },
    getRooms () {
      return this.tourRooms
        .map((r) => {
          const found = this.formData.rooms.filter((t) => t.name === r.name)
          const quantity = found.reduce((sum, s) => sum + s.qty, 0)

          return {
            name: r.name,
            price: r.price,
            capacity: r.capacity,
            quantity,
            net: r.price * quantity
          }
        })
        .filter((r) => r.quantity)
    },
    getSeats () {
      return this.tourSeats
        .map((r) => {
          const found = this.formData.seats.find((t) => t.name === r.name)

          return {
            name: r.name,
            price: r.price,
            quantity: found?.qty || 0,
            net: (found?.price || 0) * (found?.qty || 0)
          }
        })
        .filter((r) => r.quantity)
    },
    getInsurances () {
      if (!this.formData.tour) {
        return []
      }

      const travelers = this.getFollowers()
      const mapped = travelers.reduce((obj, t) => {
        const tmpObj = { ...obj }
        const insurances = this.formData.tour.insurances.filter((i) => t.insurances.includes(i.name))

        insurances.forEach((i) => {
          const quantity = (tmpObj[i.name]?.quantity || 0) + 1

          tmpObj[i.name] = {
            name: i.name,
            price: i.price,
            quantity,
            net: quantity * i.price
          }
        })

        return tmpObj
      }, {})

      return Object.values(mapped)
    },
    calcPrice () {
      if (!this.formData.tour || !this.formData.package) {
        return
      }

      const travelers = this.getFollowers()
      const bookingNet = travelers.reduce((total, follower) => {
        let sum = 0

        if (follower.age === 'kid') {
          sum += follower.includeTicket ? this.formData.package.kid.price : this.formData.package.kid.withOutTicket
        } else {
          sum += follower.includeTicket ? this.formData.package.adult.price : this.formData.package.adult.withOutTicket
        }

        if (follower.room) {
          sum += follower.room.price
        }

        if (follower.seat) {
          sum += follower.seat.price
        }

        if (follower.meal) {
          sum += follower.meal.price || 0
        }

        if (follower.insurances.length) {
          const insurances = this.formData.tour.insurances.filter((i) => follower.insurances.includes(i.name))
          sum += insurances.reduce((insuranceTotal, i) => insuranceTotal + i.price, 0)
        }

        return total + sum
      }, 0)

      const roomsPrice = this.formData.rooms.reduce((sum, r) => sum + (r.price * r.qty), 0)
      // const seatsPrice = this.formData.seats.reduce((sum, r) => sum + (r.price * r.qty), 0)
      const discountPrice = this.formData.discountPrice < 0 ? 0 : this.formData.discountPrice
      const adjustPrice = this.formData.adjustPrice < 0 ? 0 : this.formData.adjustPrice

      if (this.formData.adjustType === 'add') {
        this.formData.net = (bookingNet + roomsPrice) + adjustPrice - discountPrice
      } else {
        this.formData.net = (bookingNet + roomsPrice) - adjustPrice - discountPrice
      }
    },
    selectPackage (val) {
      this.formData.package = val
    },
    addRoom () {
      this.formData.rooms.push({
        name: '',
        price: 0,
        qty: 0,
        capacity: 1,
        roommates: ['']
      })
    },
    removeRoom (index) {
      this.formData.rooms.splice(index, 1)
    },
    selectRoom (room, index) {
      const oldMates = [...this.formData.rooms[index].roommates]

      this.formData.rooms.splice(index, 1, {
        name: room.name,
        price: room.price,
        qty: 1,
        capacity: room.capacity,
        roommates: Array(room.capacity).map((i) => oldMates[i] || '')
      })
    },
    addSeat () {
      this.formData.seats.push({
        name: '',
        description: '',
        price: 0,
        qty: 0
      })
    },
    removeSeat (index) {
      this.formData.seats.splice(index, 1)
    },
    selectSeat (seat, index) {
      this.formData.seats.splice(index, 1, {
        name: seat.name,
        description: seat.description,
        price: seat.price,
        qty: 0
      })
    },
    selectFollowerRoom (room) {
      this.formData.followers[this.followerFocusIndex].room = room
    },
    selectFollowerSeat (seat) {
      this.formData.followers[this.followerFocusIndex].seat = seat
    },
    showNumberFormat (number) {
      const num = Number.parseFloat(number)

      if (num === 0 || num) {
        return num.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }

      return '-'
    },
    adjustPassenger (val) {
      const { age, isNeedTicket, isAdd } = val

      if (isAdd) {
        this.formData.followers.push({
          age,
          prefix: 'mr',
          firstName: '',
          lastName: '',
          birthDate: '',
          email: '',
          tel: '',
          citizenNo: '',
          room: {
            name: '',
            price: 0
          },
          insurances: [],
          seat: {
            name: '',
            price: 0
          },
          meal: {
            name: '',
            price: 0
          },
          includeTicket: isNeedTicket
        })

        this.followerFocusIndex = this.formData.followers.length - 1
      } else {
        if (!this.formData.followers.length) {
          return
        }

        const removeItemIndex = this.formData.followers.findIndex((f) => f.age === age && f.includeTicket === isNeedTicket)

        if (removeItemIndex === -1) {
          return
        }

        this.followerFocusIndex = 0
        this.formData.followers.splice(removeItemIndex, 1)
      }
    },
    selectFollower (index) {
      this.followerFocusIndex = index
    },
    addFollower () {
      const followerLength = this.formData.followers.length

      this.formData.followers.push({
        age: 'adult',
        prefix: 'mr',
        firstName: '',
        lastName: '',
        birthDate: '',
        email: '',
        tel: '',
        citizenNo: '',
        room: {
          name: '',
          price: 0
        },
        insurances: [],
        seat: {
          name: '',
          price: 0
        },
        meal: {
          name: '',
          price: 0
        },
        includeTicket: true
      })

      if (followerLength) {
        this.followerFocusIndex++
      } else {
        this.followerFocusIndex = 0
      }
    },
    removeFollower () {
      const tmpIndex = this.followerFocusIndex
      this.followerFocusIndex = tmpIndex - 1
      this.formData.followers.splice(tmpIndex, 1)
    },
    clearDiscount () {
      this.formData.discountCode = ''
      this.formData.discountPrice = 0
    },
    async getTours (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.tours = []
          this.tourQuery.page = 1
        }

        const { data } = await ToursService.getItemByName(this.tourQuery)

        this.tours = data.results
      } catch (error) {
        console.error('getTours', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async applyPrivilege (privilege) {
      try {
        this.loading = true

        this.isShowDiscountDialog = false

        const { data } = await PrivilegesService.validateCode({
          code: privilege.code,
          memberId: this.formData.booker.memberId,
          tour: {
            id: this.formData.tour.id,
            startDate: this.formData.package.startDate,
            endDate: this.formData.package.endDate,
            passengers: this.formData.passengers,
            rooms: this.formData.rooms.map((r) => ({
              name: r.name,
              qty: r.qty
            }))
          }
        })

        if (!data.success) {
          throw Error(data.error.message)
        } else {
          this.formData.discountCode = data.privilege.code
          this.formData.discountPrice = data.discount
        }
      } catch (error) {
        console.error('applyPrivilege', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async getItemById () {
      try {
        this.loading = true

        const { data } = await OrdersService.getItemByOrderNo(this.$route.params.id)

        this.tourQuery.search = data.tour.name

        const { data: tours } = await ToursService.getItemByName(this.tourQuery)

        this.tours = tours.results

        if (data.followers.length) {
          this.followerFocusIndex = 0
        }

        this.isPaid = data.state === 'paid'
        this.formData = {
            ...data,
            tour: this.tours.find((t) => t.id === data.tour.id),
            booker: {
              ...data.booker,
              insurances: data.booker.insurances.map((i) => i.name)
            },
            followers: data.followers.map((f) => ({
              ...f,
              insurances: f.insurances.map((i) => i.name)
            })),
            adjustPrice: data?.adjustPrice || 0,
            adjustType: data?.adjustType || 'add',
            adjustNote: data?.adjustNote || ''
          }
        this.memoAdjustPrice = data?.adjustPrice || 0
        this.memoAdjustType = data?.adjustType || 'add'
        this.formData.passports = data?.passports?.map((p) => ({
          file: null,
          preview: p,
          mediaUrl: p
        })) || []
        this.files = data.slipImageUrl ? [
          {
            name: data.slipImageName,
            mediaUrl: data.slipImageUrl
          }
        ] : []
      } catch (error) {
        console.error('getItemById', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      try {
        this.loading = true
        const valid = await this.$refs['form'].validate()

        if (!valid) {
          return
        }

        const passports = await Promise.all(this.formData.passports.map(async (img) => {
          if (img?.file) {
            const { data: image } = await UploaderService.uploadFile(img.file)

            return image?.publicUrl || ''
          }

          return img?.mediaUrl || ''
        }))

        const slipImageName = this.files[0]?.name || ''
        let slipImageUrl = this.files[0]?.mediaUrl || ''

        if (this.files[0]?.file) {
          const { data: image } = await UploaderService.uploadFile(this.files[0].file)

          slipImageUrl = image?.publicUrl
        }

        let member = this.formData.member || null

        if (this.formData.booker.memberId) {
          const { data } = await MembersService.getItemById(this.formData.booker.memberId)

          member = data
        }

        if (!this.isEdit) {
          await OrdersService.create({
            ...this.formData,
            member,
            booker: {
              ...this.formData.booker,
              insurances: this.formData.tour.insurances.filter((i) => this.formData.booker.insurances.includes(i.name))
            },
            followers: this.formData.followers.map((f) => ({
              ...f,
              insurances: this.formData.tour.insurances.filter((i) => f.insurances.includes(i.name))
            })),
            passports,
            slipImageName,
            slipImageUrl
          })
        } else {
          await OrdersService.updateItemById({
            ...this.formData,
            member,
            booker: {
              ...this.formData.booker,
              insurances: this.formData.tour.insurances.filter((i) => this.formData.booker.insurances.includes(i.name))
            },
            followers: this.formData.followers.map((f) => ({
              ...f,
              insurances: this.formData.tour.insurances.filter((i) => f.insurances.includes(i.name))
            })),
            passports,
            slipImageName,
            slipImageUrl
          })
        }

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: `${this.isEdit ? 'แก้ไข' : 'เพิ่ม'} ข้อมูลเสร็จสิ้น`,
          type: 'success',
          timeout: 2000
        })

        this.$router.push({ name: 'OrderList' })
      } catch (error) {
        console.error('onSubmit', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async checkAdjustNote () {
      const valid = await this.$refs['form'].validate()

      if (!valid) {
        return
      }

      if (this.formData.adjustPrice !== this.memoAdjustPrice || this.formData.adjustType !== this.memoAdjustType) {
        this.showAdjustNoteDialog()
      } else {
        this.onSubmit()
      }
    },
    onConfirm (text) {
      this.formData.adjustNote = text
      this.onSubmit()
    },
    showDiscountDialog () {
      this.isShowDiscountDialog = !this.isShowDiscountDialog
    },
    showAdjustNoteDialog () {
      this.isShowAdjustNoteDialog = !this.isShowAdjustNoteDialog
    },
    toggleAdjustType () {
      this.formData.adjustType = this.formData.adjustType === 'add' ? 'minus' : 'add'
    },
    citizenNoValidate (v) {
      if (/^(?!^0+$)[a-zA-Z0-9]{6,9}$/g.test(v)) {
        return true
      }

      if (!/^[0-9]{13}$/g.test(v)) {
        return false
      }

      let sum = 0

      for (let i = 0; i < 12; i++) {
        sum += Number.parseInt(v.charAt(i)) * (13 - i)
      }

      const checkSum = (11 - (sum % 11)) % 10

      return checkSum === Number.parseInt(v.charAt(12))
    },
    isSeatEnough () {
      const seatQty = this.formData.seats.reduce((sum, s) => sum + s.qty, 0)

      return seatQty <= this.passengersQty
    }
  }
}
</script>

<style scoped>
.no-shadow-panel::before {
  box-shadow: none !important;
}
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
.v-size--x-large {
  height: 64px !important;
  border-radius: 8px !important;
  letter-spacing: 0em !important;
}
.discount-btn {
  font-size: 14px;
  color: #026EAA;
  cursor: pointer;
  text-decoration: underline;
}
.discount-btn:disabled {
  color: #828282;
}
</style>
